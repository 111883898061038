import { watch, ref } from 'vue';

import { pointTypesStore } from "@/stores/PointTypesStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { useMapTools } from "@/Composables/MapTools.js";
import { useMeasureTool } from "@/Composables/UseMeasureTool.js";
import { measureToolStore } from "../stores/MeasureToolStore.js";

import { renderHeight } from "@/Helpers.js";
import  length from '@turf/length';

var map = null;
const currentlyDragging = ref(false);

export function useDrawTargetTool() {

	const app = appStateStore();
	const mapTools = useMapTools();
	const settings = settingsStore();

	const measureToolData = measureToolStore();

	// so we can use some useful stuff in it, that should probably go into its own composable...
	const measureTool  = useMeasureTool();

	let canvas = null;

	watch(() => measureToolData.redraw, (currentValue, oldValue) => {
		draw();
	});

	// helper passthrough
	function hasTarget() {
		return measureToolData.hasTarget();
	}

	function goToTarget() {
		let coordinates = getTargetCoordinates();
		console.log(coordinates);
		mapTools.goTo(coordinates[1],coordinates[0]);
	}

	function getTargetCoordinates() {
		console.log(hasTarget());
		console.log(measureToolData.targetGeojson.features);
		if (!hasTarget()) return false;
		return measureToolData.targetGeojson.features[0].geometry.coordinates;
	}

	function constructLayers() {

		canvas = app.map.getCanvasContainer();

		let dotColour = '#00A';
		let selectedDotColour = '#44A';
		let lineColour = '#44A';

		if (app.isMapDark())
		{
			dotColour = '#F60';
			selectedDotColour = '#F60';
			lineColour = '#F60';
		}

		app.map.addSource('target-source', {
			'type': 'geojson',
			'data': {
				'type': 'FeatureCollection',
				'features': [],
			}
		});
		app.map.addLayer({
			id: 'target-point',
			type: 'circle',
			source: 'target-source',
			paint: {
				'circle-radius': ["case",["any",["has","key"], ["has","location"]],
					20, 10
				],
				'circle-color': ["case",["any",["has","key"], ["has","location"]],
					selectedDotColour, dotColour
				],
			},
			filter: ['in', '$type', 'Point']
		}, 'measure-slot');


		app.map.addLayer({
			id: 'target-point-touch',
			type: 'circle',
			source: 'target-source',
			paint: {
				'circle-radius': 40,
				"circle-opacity": 0,
				"circle-stroke-opacity": .5,
				"circle-stroke-width": ["case",["any",["has","key"], ["has","location"]],
					5, 2
				],
				"circle-stroke-color": lineColour
			},
			filter: ['in', '$type', 'Point']
		}, 'measure-slot');

		app.map.on('mouseenter', 'target-point', () => {
			mouseEnterPoint();
		});

		app.map.on('mouseleave', 'target-point', () => {
			mouseLeavePoint();
		});


		app.map.on('mousedown', 'target-point', (e) => {
			mouseDownFunction(e);
		});

		app.map.on('touchstart', 'target-point-touch', (e) => {
			touchStartFunction(e);
		});

		draw();

	}



	function mouseDownFunction(e) {
		// Prevent the default map drag behavior.
		e.preventDefault();

		app.map.setPaintProperty('target-point-touch', 'circle-stroke-opacity', 1);

		//getCurrentDragID(e, 'target-point');
		//console.log(measureToolData.measureGeojson.features);

		canvas.style.cursor = 'grab';

		app.map.on('mousemove', onMove);
		app.map.once('mouseup', onUp);
	}

	function touchStartFunction(e) {
		// console.log('touch started');
		//console.log(e);

		if (e.points.length !== 1) return;


		app.map.setPaintProperty('target-point-touch', 'circle-stroke-opacity', 1);

		// Prevent the default map drag behavior.
		e.preventDefault();

		//getCurrentDragID(e, 'target-point-touch');

		app.map.on('touchmove', onMove);
		app.map.once('touchend', onUp);
	}
	function mouseEnterPoint() {
		canvas.style.cursor = 'move';
	}
	function mouseLeavePoint() {
		canvas.style.cursor = '';
	}



	function onUp(e) {
		// console.log('finished dragging');
		const coords = e.lngLat;

		setTimeout(() => {
			currentlyDragging.value=false;
			// console.log('Finished currentlyDragging = false');
		}, 200);

		// Print the coordinates of where the point had
		// finished being dragged to on the map.
		// coordinates.style.display = 'block';
		// coordinates.innerHTML = `Longitude: ${coords.lng}<br />Latitude: ${coords.lat}`;
		// canvas.style.cursor = '';

		app.map.setPaintProperty('target-point-touch', 'circle-stroke-opacity', .5);

		// Unbind mouse/touch events
		app.map.off('mousemove', onMove);
		app.map.off('touchmove', onMove);
	}

	function onMove(e) {
		//console.log('dragging ' + currentlyDragging.value);
		currentlyDragging.value=true;

		//console.log(e);
		const coords = e.lngLat;

		// Set a UI indicator for dragging.
		canvas.style.cursor = 'grabbing';


		let nearestPoint = measureTool.findNearest(coords.lat, coords.lng);

		// Update the Point feature in `geojson` coordinates
		// and call setData to the source layer `point` on it.

		// console.log(measureToolData.targetGeojson);
		// console.log(nearestPoint);

		//settings.target.coordinates = [nearestPoint.newLong, nearestPoint.newLat];

		measureToolData.targetGeojson.features[0].geometry.coordinates = [nearestPoint.newLong, nearestPoint.newLat];

		if (nearestPoint.snappedPointKey) {
			measureToolData.targetGeojson.features[0].properties.key=nearestPoint.snappedPointKey;
		} else {
			// delete if not snapped to it
			delete measureToolData.targetGeojson.features[0].properties.key;
		}

		if (nearestPoint.snappedLocation) {
			measureToolData.targetGeojson.features[0].properties.location=nearestPoint.snappedLocation;
		} else {
			// delete if not snapped to it
			delete measureToolData.targetGeojson.features[0].properties.location;
		}


		measureToolData.targetMoved++;
		//console.log(measureToolData.targetGeojson);
		draw();
	}


	function destructLayers() {

		app.map.off('mouseenter', 'target-point', () => {
			mouseEnterPoint();
		});

		app.map.off('mouseleave', 'target-point', () => {
			mouseLeavePoint();
		});

		app.map.off('mousedown', 'target-point', (e) => {
			mouseDownFunction();
		});

		app.map.off('touchstart', 'target-point-touch', (e) => {
			touchStartFunction();
		});

		if (app.map.getLayer('target-point')) app.map.removeLayer('target-point');
		if (app.map.getLayer('target-point-touch')) app.map.removeLayer('target-point-touch');
		if (app.map.getSource('target-source')) app.map.removeSource('target-source');
	}


	function disablePlaceTarget() {
		if (measureToolData.targetMode) togglePlaceTarget();
	}


	function togglePlaceTarget() {

		if (measureToolData.targetMode) {
			// turn off measure mode
			measureToolData.targetMode = false;

			app.map.off('click', onMapClick);


		} else {
			// turn on measure mode
			measureToolData.targetMode = true;

			// add click listener
			app.map.on('click', onMapClick);
		}
	}

	function clearTarget() {
		measureToolData.clearTarget();
		app.map.getSource('target-source').setData(measureToolData.targetGeojson);

		 // turn off these columns
		settings.showTargetDistanceLabel = false;
		settings.showTargetBearingLabel = false;
		settings.legendDistanceToTarget = false;
		settings.legendBearingToTarget = false;
	}


	function onMapClick(e) {
		if (currentlyDragging.value) return;

		const features = app.map.queryRenderedFeatures(e.point, {
			layers: ['target-point']
		});
		 
		if (measureToolData.targetGeojson.features.length==1) {
			measureToolData.targetGeojson.features = [];
		}

		let nearest = measureTool.findNearest(e.lngLat.lat, e.lngLat.lng);

		const point = {
			'type': 'Feature',
			'geometry': {
			'type': 'Point',
			'coordinates': [nearest.newLong, nearest.newLat]
		},
		'properties': {
			'id': String(new Date().getTime())
			}
		};


		if (nearest.snappedPointKey) {
			point.properties.key = nearest.snappedPointKey;
		}
		if (nearest.snappedLocation) {
			point.properties.location = nearest.snappedLocation;
		}

		measureToolData.targetGeojson.features.push(point);


		settings.showTargetDistanceLabel = true;
		settings.legendDistanceToTarget = true;

		// turn off placing target once done
		togglePlaceTarget();
		draw();

	}



	function draw() {
		if (!app?.map?.getSource('target-source')) return;

		if (measureToolData.targetGeojson.features.length>=1) {
			app.map.getSource('target-source').setData(measureToolData.targetGeojson);
		}
		return;


	}

	return {
		constructLayers,
		destructLayers,
		togglePlaceTarget,
		clearTarget,
		draw,
		hasTarget,
		measureToolData,
		goToTarget,
		getTargetCoordinates,
		disablePlaceTarget,
	};
}
