import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';
import { appStateStore } from "@/stores/AppStateStore.js";

import {roundDecimals } from '../Helpers.js';


export const skySightStore = defineStore('skySightStore', () => {

	const currentRegion = ref(null);
	const currentLayer = ref(null);
	const app = appStateStore();

	function getRegion(id) {
		for (var i=0; i<regions.length; i++) {
			//console.log(regions[i].id + ' vs ' + id);
			if (regions[i].id == id) return regions[i];
		}
		return null;
	}
	function getLayer(id) {
		for (var i=0; i<layers.length; i++) {
			if (layers[i].id == id) return layers[i];
		}
		return null;
	}

	function scaledColours() {
		//console.log('getting scaled colours');
		//console.log(currentLayer.value);
		let newColours = [];

		let distance = currentLayer.value.max - currentLayer.value.min;
		//console.log('distance ' + distance);
		let total_steps = roundDecimals((currentLayer.value.max - currentLayer.value.min) / currentLayer.value.step, 0);
		//console.log('total steps ' + total_steps);

		if (total_steps < colours.length ) {
			//console.log('fewer steps! ' + total_steps + ' / ' + colours.length);

			let newStepSize = (colours.length-1)/total_steps;
			//console.log("newStepSize: " + newStepSize);

			for (var i=0; i<=total_steps; i++) {
				newColours.push(colours[Math.floor(i*newStepSize)]);
			}
			//newColours.push(colours[colours.length-1]);
			//console.log("newColours.length: " );
			//console.log(newColours.length);
			//console.log(newColours);
			return newColours;
		}

		return colours;
	}

	function getRegionByTimezone() {
		let region = null;

		let centerTimezone = app.getMapCenterTimezone();

		let split = centerTimezone.split('/');


		switch (split[0]) {
			case 'Australia':
				if (centerTimezone=='Australia/Perth') {
					//console.log('Found WEST_AUS');
					region = getRegion('WEST_AUS');
				} else {
					//console.log('Found EAST_AUS');
					region = getRegion('EAST_AUS');
				}
				break;
			case 'Europe':
				//console.log('Found EUROPE');
				region = getRegion('EUROPE');
				break;
			case 'Africa':
				//console.log('Found SANEW');
				region = getRegion('SANEW');
				break;
			case 'America':

				if (split[1]=='Argentina') {
					//console.log('Found ARGENTINA_CHILE');
					region = getRegion('ARGENTINA_CHILE');
				} else {
					//console.log('Found WEST_US');
					region = getRegion('WEST_US');
				}
				break;
			case 'Asia':
				//console.log('Found JAPAN');
				region = getRegion('JAPAN');
				break;
		}



		// exceptions to the above
		switch (centerTimezone) {
			case 'Asia/Tokyo':
				region = getRegion('JAPAN');
				break;
			case 'Asia/Kolkata':
			case 'Asia/Shanghai':
				region = getRegion('BIR');
				break;
			case 'America/Santiago':
				//console.log('Found ARGENTINA_CHILE');
				region = getRegion('ARGENTINA_CHILE');
				break;
			case 'America/Campo_Grande':
			case 'America/Bahia':
			case 'America/Sao_Paulo':
			case 'America/Recife':
			case 'America/Fortaleza':
			case 'America/Bahia':
			case 'America/Cuiaba':
				//console.log('Found BRAZIL');
				region = getRegion('BRAZIL');
				break;
			case 'Australia/Perth':
				//console.log('Found WA');
				region = getRegion('WA');
				break;
			case 'Pacific/Auckland':
				//console.log('Found NZ');
				region = getRegion('NZ');
				break;
			case 'America/New_York':
			case 'America/Chicago':
			case 'America/Toronto':
			case 'America/Winnipeg':
			case 'America/Nassau':
				//console.log('Found EAST_US');
				region = getRegion('EAST_US');
				break;
		}

		if (region) //console.log('Found region of ' + region.id);
		return region;
	}

	// function currentRegion()
	// {
	// 	for (var i=0; i<regions.length; i++) {
	// 		if (inBoundingBox(lat, long, ))
	// 	}
	// }


	const regions = [
	{
		"projection": "lambert",
		"name": "Western US",
		"id": "WEST_US",
		"update": "interval",
		"update_interval": 300,
		"update_times": [
			28800,
			64800
		],
		"bounds": [
			"15.667567567567566",
			"-139.938",
			"61.332432432432434",
			"-78.162"
		],
		"tz": "America/Los_Angeles"
	},
	{
		"projection": "lambert",
		"name": "Eastern US",
		"id": "EAST_US",
		"update": "interval",
		"update_interval": 300,
		"update_times": [
			28800,
			64800
		],
		"bounds": [
			"5.987621621621621",
			"-116.501",
			"55.84437837837838",
			"-53.93299999999999"
		],
		"tz": "America/New_York"
	},
	{
		"projection": "lambert",
		"name": "Himalayas",
		"id": "BIR",
		"update": "interval",
		"update_interval": 300,
		"update_times": [
			28800,
			64800
		],
		"tz": "Asia/Kolkata"
	},
	{
		"projection": "lambert",
		"name": "Europe",
		"id": "EUROPE",
		"update": "interval",
		"update_interval": 300,
		"update_times": [
			28800,
			64800
		],
		"bounds": [
			"38.50337837837838",
			"-15.363999999999999",
			"65.74662162162161",
			"35.396"
		],
		"tz": "Europe/Warsaw"
	},
	{
		"projection": "mercator",
		"name": "Eastern Australia",
		"id": "EAST_AUS",
		"update": "interval",
		"update_interval": 300,
		"update_times": [
			28800,
			64800
		],
		"bounds": [
			"-50.03716216216216",
			"117.46500000000002",
			"-14.462837837837839",
			"165.25500000000002"
		],
		"tz": "Australia/Sydney"
	},
	{
		"projection": "lambert",
		"name": "Western Australia",
		"id": "WA",
		"update": "interval",
		"update_interval": 300,
		"update_times": [
			28800,
			64800
		],
		"bounds": [
			"-38.19513513513513",
			"102.31",
			"-19.924864864864865",
			"129.35"
		],
		"tz": "Australia/Perth"
	},
	{
		"projection": "lambert",
		"name": "New Zealand",
		"id": "NZ",
		"update": "interval",
		"update_interval": 300,
		"update_times": [
			28800,
			64800
		],
		"bounds": [
			"-51.441495495495495",
			"161.50099999999998",
			"-31.4505045045045",
			"182.221"
		],
		"tz": "Pacific/Auckland"
	},
	{
		"projection": "lambert",
		"name": "Japan",
		"id": "JAPAN",
		"update": "interval",
		"update_interval": 300,
		"update_times": [
			28800,
			64800
		],
		"bounds": [
			"25.34914414414414",
			"128.05333333333334",
			"49.060855855855856",
			"147.14666666666665"
		],
		"tz": "Asia/Tokyo"
	},
	{
		"projection": "mercator",
		"name": "Argentina/Chile",
		"id": "ARGENTINA_CHILE",
		"update": "interval",
		"update_interval": 300,
		"update_times": [
			28800,
			64800
		],
		"bounds": [
			"-50.89345945945946",
			"269.50399999999996",
			"-19.17454054054054",
			"315.872"
		],
		"tz": "America/Argentina/Buenos_Aires"
	},
	{
		"projection": "mercator",
		"name": "South Africa",
		"id": "SANEW",
		"update": "interval",
		"update_interval": 300,
		"update_times": [
			28800,
			64800
		],
		"bounds": [
			"-41.10351351351352",
			"-1.1489999999999974",
			"-13.276486486486487",
			"45.447"
		],
		"tz": "Africa/Johannesburg"
	},
	{
		"projection": "lambert",
		"name": "Brazil",
		"id": "BRAZIL",
		"update": "interval",
		"update_interval": 300,
		"update_times": [
			28800,
			64800
		],
		"bounds": [
			"-37.04518918918919",
			"-69.038",
			"3.333189189189188",
			"-27.278000000000002"
		],
		"tz": "America/Sao_Paulo"
	},
	// {
	// 	"projection": "mercator",
	// 	"name": "South Africa",
	// 	"id": "MOROCCO",
	// 	"update": "interval",
	// 	"update_interval": 300,
	// 	"update_times": [
	// 		28800,
	// 		64800
	// 	],
	// 	"bounds": [
	// 		"-41.10351351351352",
	// 		"-1.1489999999999974",
	// 		"-13.276486486486487",
	// 		"45.447"
	// 	],
	// 	"tz": "Africa/Casablanca"
	// }
	];

	const layers = [
		{
			id: 'zsfclclmask',
			name: 'Cu Cloudbase',
			type: "hgt",
			unit: "m",
			min: 0,
			max: 4800,
			step: 300,
		},
		{
			id: 'wstar_bsratio',
			name: 'Thermal Strength & B/S Ratio',
			unit: "m/s",
			min: 0.5,
			max: 4.0,
			step: 0.5,
		},
		{
			id: 'hwcrit',
			name: 'Height of Thermals',
			unit: "m",
			min: 300,
			max: 4800,
			step: 300,
		},
		{
			id: 'dwcrit',
			name: 'Depth of Thermals (AGL)',
			unit: "m",
			min: 300,
			max: 3600,
			step: 300,
		},
		{
			id: 'ridge',
			name: 'Ridge Lift',
			unit: "m/s",
			min: 0.1,
			max: 1.2,
			step: 0.1,
		},
		{
			id: 'zblclmask',
			name: 'Overdevelopment',
			unit: "%",
			min: 5,
			max: 95,
			step: 10,
		},
		{
			id: 'sfcwind0',
			name: 'Surface Wind',
			unit: "m/s",
			min: 0,
			max: 12,
			step: 1,
		},
		{
			id: 'blwind',
			name: 'Thermal Layer Wind (Avg)',
			unit: "m/s",
			min: 1,
			max: 16,
			step: 1,
		},
		{
			id: 't2c',
			name: 'Surface Temperature',
			unit: "c",
			min: -6,
			max: 48,
			step: 2,
		},
		{
			id: 'wblmaxmin',
			name: 'Convergence',
			unit: "m/s",
			min: -0.25,
			max: 0.75,
			step: 0.1,
		},
		{
			id: 'w_1000',
			name: 'V. Vel 1km/3kft',
			unit: "m/s",
			min: -3.0,
			max: 3.6,
			step: 0.3,
		},
		{
			id: 'w_2000',
			name: 'V. Vel 2km/7kft',
			unit: "m/s",
			min: -3.0,
			max: 3.6,
			step: 0.3,
		},
		{
			id: 'w_3000',
			name: 'V. Vel 3km/10kft',
			unit: "m/s",
			min: -3.0,
			max: 3.6,
			step: 0.3,
		},
		{
			id: 'w_5000',
			name: 'V. Vel 5km/16kft',
			unit: "m/s",
			min: -3.0,
			max: 3.6,
			step: 0.3,
		},
		{
			id: 'w_7000',
			name: 'V. Vel 7km/23kft',
			unit: "m/s",
			min: -3.0,
			max: 3.6,
			step: 0.3,
		},
		{
			id: 'freezing',
			name: 'Freezing Level',
			unit: "m",
			min: 300,
			max: 5700,
			step: 300,
		},
		{
			id: 'potfd',
			name: 'XC Speed (Glid)',
			unit: "kph",
			min: 40,
			max: 160,
			step: 10,
		},
		{
			id: 'potfd_hg',
			name: 'XC Speed (Para)',
			unit: "kph",
			min: 10,
			max: 50,
			step: 5,
		},
		{
			id: 'cape',
			name: 'CAPE / Storms',
			unit: "J",
			min: 100,
			max: 3100,
			step: 300,
		},
		{
			id: 'accrain',
			name: 'Rain',
			unit: "mm",
			min: 0,
			max: 5,
			step: 0.5,
		},
		// {
		// 	id: 'pfdtothg',
		// 	name: 'Pot. Distance (Para)',
		// 	min: 30,
		// 	max: 330,
		// 	step: 30,
		// },
		// {
		// 	id: 'pfdtot',
		// 	name: 'Pot. Distance (Glid)',
		// 	min: 50,
		// 	max: 950,
		// 	step: 100,
		// },
	]


	// smaller array of spectrum
	const colours = ["rgb(0, 0, 143)", "rgb(0, 0, 228)", "rgb(0, 58, 255)", "rgb(0, 144, 255)", "rgb(0, 229, 255)", "rgb(1, 237, 199)", "rgb(4, 211, 119)", "rgb(7, 186, 38)", "rgb(66, 197, 11)", "rgb(149, 223, 7)", "rgb(232, 248, 1)", "rgb(255, 233, 0)", "rgb(255, 201, 0)", "rgb(255, 170, 0)", "rgb(255, 118, 0)", "rgb(255, 62, 0)", "rgb(255, 8, 0)", "rgb(206, 0, 40)", "rgb(150, 0, 85)", "rgb(94, 0, 131)"];

	return { 
		regions,
		colours,
		scaledColours,
		currentRegion,
		currentLayer,
		getRegion,
		getLayer,
		getRegionByTimezone,
		layers,

	}

});


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(skySightStore, import.meta.hot))
}



