import { watch, ref } from 'vue';

import { pointsStore } from "../stores/PointsStore.js";
import { pointTypesStore } from "@/stores/PointTypesStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { useMapTools } from "@/Composables/MapTools.js";
import { measureToolStore } from "../stores/MeasureToolStore.js";

import { renderHeight } from "@/Helpers.js";
import length from '@turf/length';
import { featureEach } from "@turf/meta";
import { distance } from "@turf/distance";
import { point } from "@turf/helpers";


const currentlyDragging = ref(false);

// version 2 of the puretrack measure tool
export function useMeasureTool() {

	const app = appStateStore();
	const points = pointsStore();

	const measureToolData = measureToolStore();

	let canvas = null;

	const currentDragPoint = ref(null);
	const currentDragPointIndex = ref(null);


	watch(() => measureToolData.redraw, (currentValue, oldValue) => {
		draw();
	});


	function disableAddMeasure() {
		if (measureToolData.measureMode) toggleMeasure();
	}

	function toggleMeasure() {

		if (measureToolData.measureMode) {
			// turn off measure mode
			measureToolData.measureMode = false;

			app.map.off('click', onMapClick);


		} else {
			// turn on measure mode
			measureToolData.measureMode = true;

			// add click listener
			app.map.on('click', onMapClick);
		}
	}




	function constructLayers() {
		//console.log('constructing layers');

		canvas = app.map.getCanvasContainer();

		let dotColour = '#A00';
		let selecectedDotColour = '#A44';
		let lineColour = '#700';

		if (app.isMapDark())
		{
			dotColour = '#F60';
			selecectedDotColour = '#F60';
			lineColour = '#FFF';
		}

		app.map.addSource('measure-source', {
			'type': 'geojson',
			'data': {
				'type': 'FeatureCollection',
				'features': [],
			}
		});
		app.map.addLayer({
			id: 'measure-points',
			type: 'circle',
			source: 'measure-source',
			paint: {
				'circle-radius': ["case", ["any",["has","key"], ["has","location"]],
					20, 10
				],
				'circle-color': ["case",["any",["has","key"], ["has","location"]],
					selecectedDotColour, dotColour
				],
			},
			filter: ['in', '$type', 'Point']
		}, 'measure-slot');

		app.map.addLayer({
			id: 'measure-points-touch',
			type: 'circle',
			source: 'measure-source',
			paint: {
				'circle-radius': 40,
				"circle-opacity": 0,
				"circle-stroke-opacity": 0,
				"circle-stroke-width": ["case",["any",["has","key"], ["has","location"]],
					5, 2
				],
				"circle-stroke-color": lineColour
			},
			filter: ['in', '$type', 'Point']
		}, 'measure-slot');



		app.map.addLayer({
			id: 'measure-lines',
			type: 'line',
			source: 'measure-source',
			layout: {
				'line-cap': 'round',
				'line-join': 'round'
			},
			paint: {
				'line-color': lineColour,
				'line-color': lineColour,
				 'line-dasharray': [1, 2, 1],
				'line-width': 5
			},
			filter: ['in', '$type', 'LineString']
		}, 'measure-slot');


		app.map.on('mouseenter', 'measure-points', () => {
			mouseEnterPoint();
		});

		app.map.on('mouseleave', 'measure-points', () => {
			mouseLeavePoint();
		});


		app.map.on('mousedown', 'measure-points', (e) => {
			mouseDownFunction(e);
		});

		app.map.on('touchstart', 'measure-points-touch', (e) => {
			touchStartFunction(e);
		});

		draw();
	}


	function getSnapDistance() {

		let zoom = Math.round(app.map.getZoom());
		let snapDistance = 1;
		if (zoom>6)  snapDistance = 4;
		if (zoom>7)  snapDistance = 3;
		if (zoom>8)  snapDistance = 2;
		if (zoom>9)  snapDistance = 1.5;
		if (zoom>10) snapDistance = .3;
		if (zoom>13) snapDistance = .1;
		if (zoom>14) snapDistance = .08;
		if (zoom>15) snapDistance = .06;
		if (zoom>16) snapDistance = .05;
		if (zoom>17) snapDistance = .02;

		return snapDistance;
	}


	function findNearest(lat, long) {

		// check for snapping
		let snapLocations = app.map.getSource('locations')._data;
		let snapPoints = app.map.getSource('markers')._data;

		let newLat = lat;
		let newLong = long;

		let snappedPointKey = null;
		let snappedLocation = null;

		// if the distance of the dragging point is under a certain threshold
		var snapDistance = getSnapDistance();
		
		featureEach(snapLocations, (feature) => {
			let dist = distance(feature, point([long, lat]));

			if (dist < snapDistance) {
				newLat = feature.geometry.coordinates[1];
				newLong = feature.geometry.coordinates[0];
				snappedLocation = true;
			}
		});

		featureEach(snapPoints, (feature) => {
			let dist = distance(feature, point([long, lat]));
			
			if (dist < snapDistance) {
				newLat = feature.geometry.coordinates[1];
				newLong = feature.geometry.coordinates[0];

				// save as snapped to this object
				snappedPointKey = feature.properties.key;
				//console.log(snappedPointKey);
			}
		});

		// console.log('returning:');
		// console.log(newLat);
		// console.log(newLong);

		return {
			newLat: newLat,
			newLong: newLong,
			snappedPointKey: snappedPointKey,
			snappedLocation: snappedLocation

		}

	}



	function onMove(e) {
		//console.log('dragging ' + currentlyDragging.value);
		currentlyDragging.value=true;

		// console.log(e);
		const coords = e.lngLat;

		// Set a UI indicator for dragging.
		canvas.style.cursor = 'grabbing';


		let nearestPoint = findNearest(coords.lat, coords.lng);

		// Update the Point feature in `geojson` coordinates
		// and call setData to the source layer `point` on it.

		measureToolData.measureGeojson.features[currentDragPointIndex.value].geometry.coordinates = [nearestPoint.newLong, nearestPoint.newLat];

		let lastItem = measureToolData.measureGeojson.features.length;
		measureToolData.measureGeojson.features[lastItem-1].geometry.coordinates[currentDragPointIndex.value] = [nearestPoint.newLong, nearestPoint.newLat];

		if (nearestPoint.snappedPointKey) {
			measureToolData.measureGeojson.features[currentDragPointIndex.value].properties.key=nearestPoint.snappedPointKey;
		} else {
			// delete if not snapped to it
			delete measureToolData.measureGeojson.features[currentDragPointIndex.value].properties.key;
		}

		if (nearestPoint.snappedLocation) {
			measureToolData.measureGeojson.features[currentDragPointIndex.value].properties.location=nearestPoint.snappedLocation;
		} else {
			// delete if not snapped to it
			delete measureToolData.measureGeojson.features[currentDragPointIndex.value].properties.location;
		}

		//console.log(measureToolData.measureGeojson);
		draw();
	}

	function onUp(e) {
		// console.log('finished dragging');
		const coords = e.lngLat;

		setTimeout(() => {
			currentlyDragging.value=false;
			// console.log('Finished currentlyDragging = false');
		}, 200);

		// Print the coordinates of where the point had
		// finished being dragged to on the map.
		// coordinates.style.display = 'block';
		// coordinates.innerHTML = `Longitude: ${coords.lng}<br />Latitude: ${coords.lat}`;
		// canvas.style.cursor = '';

		app.map.setPaintProperty('measure-points-touch', 'circle-stroke-opacity', 0);

		// Unbind mouse/touch events
		app.map.off('mousemove', onMove);
		app.map.off('touchmove', onMove);

	}

	function mouseDownFunction(e) {
		// Prevent the default map drag behavior.
		e.preventDefault();

		app.map.setPaintProperty('measure-points-touch', 'circle-stroke-opacity', 1);

		getCurrentDragID(e, 'measure-points');
		//console.log(measureToolData.measureGeojson.features);

		canvas.style.cursor = 'grab';

		app.map.on('mousemove', onMove);
		app.map.once('mouseup', onUp);
	}

	function touchStartFunction(e) {
		// console.log('touch started');
		//console.log(e);

		if (e.points.length !== 1) return;


		app.map.setPaintProperty('measure-points-touch', 'circle-stroke-opacity', 1);

		// Prevent the default map drag behavior.
		e.preventDefault();

		getCurrentDragID(e, 'measure-points-touch');

		app.map.on('touchmove', onMove);
		app.map.once('touchend', onUp);
	}


	function getCurrentDragID(e, layer) {

		const features = app.map.queryRenderedFeatures(e.point, {
			layers: [layer]
		});
		//console.log(features);

		if (features.length==0) return;

		currentDragPoint.value = features[0].properties.id;
		//console.log(currentDragPoint.value);


		// figure out which item we are dragging
		//console.log(measureToolData.measureGeojson.features.length);

		for (var i=0; i<measureToolData.measureGeojson.features.length; i++) {
		//	console.log(measureToolData.measureGeojson.features[i]);

			if (measureToolData.measureGeojson.features[i].properties?.id == currentDragPoint.value) {
				currentDragPointIndex.value = i;
			}
		}
	}

	function mouseEnterPoint() {
		//app.map.setPaintProperty('measure-points', 'circle-color', '#3bb2d0');
		//app.map.setPaintProperty('measure-points', 'circle-radius', 14);
		canvas.style.cursor = 'move';
	}
	function mouseLeavePoint() {
		//app.map.setPaintProperty('measure-points', 'circle-color', '#3887be');
		//app.map.setPaintProperty('measure-points', 'circle-radius', 8);
		canvas.style.cursor = '';
	}


	function destructLayers() {

		app.map.off('mouseenter', 'measure-points', () => {
			mouseEnterPoint();
		});

		app.map.off('mouseleave', 'measure-points', () => {
			mouseLeavePoint();
		});

		app.map.off('mousedown', 'measure-points', (e) => {
			mouseDownFunction();
		});

		app.map.off('touchstart', 'measure-points-touch', (e) => {
			touchStartFunction();
		});

		if (app.map.getLayer('measure-lines')) app.map.removeLayer('measure-lines');
		if (app.map.getLayer('measure-points')) app.map.removeLayer('measure-points');
		if (app.map.getLayer('measure-points-touch')) app.map.removeLayer('measure-points-touch');
		if (app.map.getSource('measure-source')) app.map.removeSource('measure-source');
	}


	function clearMeasure() {
		if (measureToolData.measureGeojson.features.length > 1) measureToolData.measureGeojson.features.splice(0);
		measureToolData.measureDistance = null;
		app.map.getSource('measure-source').setData(measureToolData.measureGeojson);

		measureToolData.measureLinestring.geometry.coordinates.splice(0);
	}



	function onMapClick(e) {
		// console.log(e);
		// console.log(currentlyDragging.value);
		if (currentlyDragging.value) return;

		const features = app.map.queryRenderedFeatures(e.point, {
			layers: ['measure-points']
		});


		// console.log('features');
		// console.log(features);
		// console.log('measureGeojson in mapclick');
		// console.log(measureToolData.measureGeojson);

		// Remove the linestring from the group
		// so we can redraw it based on the points collection.
		if (measureToolData.measureGeojson.features.length > 1) measureToolData.measureGeojson.features.pop();
		 
		// If a feature was clicked, remove it from the app.map.
		if (features.length) {
			//console.log('features is >1');
			// only allow deletion of points if more than 2
			if (measureToolData.measureGeojson.features.length > 2) {
				const id = features[0].properties.id;
				measureToolData.measureGeojson.features = measureToolData.measureGeojson.features.filter(
					(point) => point.properties.id !== id
				);
			}
			
		} else {

			//console.log(e.lngLat.lat);

			// check if we clicked near a point
			let nearest = findNearest(e.lngLat.lat, e.lngLat.lng);

			//console.log(nearest);

			//console.log('adding a new point');
			// add a new point
			let point = {
				'type': 'Feature',
				'geometry': {
				'type': 'Point',
				'coordinates': [nearest.newLong, nearest.newLat]
			},
			'properties': {
				'id': String(new Date().getTime())
				}
			};

			if (nearest.snappedPointKey) {
				point.properties.key = nearest.snappedPointKey;
			}
			if (nearest.snappedLocation) {
				point.properties.location = nearest.snappedLocation;
			}

			//console.log(point);

			measureToolData.measureGeojson.features.push(point);
		}

		// create the line
		if (measureToolData.measureGeojson.features.length > 1) {
			measureToolData.measureLinestring.geometry.coordinates = measureToolData.measureGeojson.features.map(
				(point) => point.geometry.coordinates
			);
		}
		measureToolData.measureGeojson.features.push(measureToolData.measureLinestring);
		
		draw();
	}


	function calculateLineLength()
	{
		
	}

	function draw() {


		//console.log('drawing measure tool');
		//if (measureToolData.measureLinestring) console.log(measureToolData.measureLinestring);
		// console.log(measureToolData.measureGeojson);

		// Populate the distanceContainer with total distance
		measureToolData.measureDistance = length(measureToolData.measureLinestring)*1000; // convert km to meters
		//console.log(measureToolData.measureDistance);

		if (measureToolData.measureGeojson.features.length>1) {

			if (!app?.map?.getSource('measure-source')) return;

			// console.log('Setting data');
			app.map.getSource('measure-source').setData(measureToolData.measureGeojson);
		}
	}


	return {
		draw,
		constructLayers,
		destructLayers,
		clearMeasure,
		toggleMeasure,
		getSnapDistance,
		findNearest,
		measureToolData,
		disableAddMeasure,
		
	};
}
